<template>
  <g :id="`MaleAverageAvatar_${uniqueIdSuffixA}`">
    <circle fill="#D0D0D0" cx="14.3" cy="91.6" r="14.3" />
    <path
      fill="#868686"
      d="M22.1,98.6l-1.5-0.4L19,97.8l-1.1-0.3c0,0,0,0,0,0c-0.5-0.1-0.8-0.5-0.8-1v-2.1v0c0.8-0.6,1.3-1.5,1.3-2.6
		v-0.3h0c0,0,0.4-0.2,0.5-0.3c0.3-0.2,0.4-0.3,0.4-0.7v-0.7c0-0.4-0.1-0.7-0.4-0.7h-0.4l0-0.1l0-0.1l-0.1-1.5c0-2-1.6-3.6-3.6-3.6
		h-0.6c-2,0-3.6,1.6-3.6,3.6V89h0l0,0.1H9.9c-0.4,0-0.4,0.3-0.4,0.7v0.7c0,0.4,0.2,0.5,0.4,0.7c0.1,0.1,0.5,0.3,0.5,0.3h0v0.3
		c0,1.1,0.5,2,1.3,2.6v0l0,0v2.1c0,0.5-0.5,0.9-0.9,1L10,97.7l-1.6,0.4L7,98.6c-0.6,0.2-1.1,0.5-1.4,1c1,1.2,2.2,2.2,3.6,2.9
		c0.6,0.3,1.3,0.6,2,0.8c0.6,0.2,1.2,0.3,1.8,0.4c0.5,0.1,1,0.1,1.5,0.1s1,0,1.5-0.1c0.6-0.1,1.3-0.2,1.8-0.4c0.7-0.2,1.3-0.5,2-0.8
		c1.4-0.7,2.6-1.7,3.6-2.9C23.2,99.1,22.7,98.7,22.1,98.6z"
  /></g>
</template>
<script>
export default {
  computed: {
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
  },
};
</script>
